import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong } from "@quarkly/widgets";
const defaultProps = {
	"margin": "0px 0px 0px 0px",
	"color": "#ffffff",
	"font": "30px rubik",
	"text-align": "center",
	"padding": "0px 0px 0px 0px",
	"children": <>
		We{" "}
		<Strong
			overflow-wrap="normal"
			word-break="normal"
			white-space="normal"
			text-indent="0"
			text-overflow="clip"
			hyphens="manual"
			user-select="auto"
			pointer-events="auto"
		>
			craft new realities
		</Strong>
	</>
};
const overrides = {};

const Title = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Text {...rest}>
		{children}
	</Text>;
};

Object.assign(Title, { ...Text,
	defaultProps,
	overrides
});
export default Title;